import { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom/client'
import { Message } from '../../models/types'
import { MessageType, WidgetMode } from '../../models/enums'
import { handleTypeMessageFromParentWindow } from '../../util/messageHandler'
import {
  allowedOrigins,
  iframeOrigin,
  triggerButtonId,
  triggerButtonRetryDuration,
  triggerButtonRetryTimeout,
  widgetHolderId,
  widgetMyBookingId,
} from '../../constants/app'
import { getFontStyles } from './helpers'

const WidgetLoader = () => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null)
  const startTriggerButton = useRef<HTMLElement[] | null>(null)
  const startTriggerButtonMyBooking = useRef<HTMLElement[] | null>(null)
  const [open, setOpen] = useState(false)
  const [startingMode, setStartingMode] = useState(MessageType.Start)
  const { mode, apiToken, clinicIds, treatmentIds, theme, integrationType } =
    window.tdlWidgetConfig || {}
  const [hostURLParams, setHostURLParams] = useState<URLSearchParams>(
    new URLSearchParams(location.search)
  )

  useEffect(() => {
    setHostURLParams(new URLSearchParams(location.search))
  }, [])

  useEffect(() => {
    const onStart = (startingMode: MessageType = MessageType.Start) => {
      if (mode === WidgetMode.Modal) {
        const pBody = document.getElementsByTagName('body')[0]
        pBody.style.overflow = 'hidden'
        pBody.style.position = 'fixed'
      }

      if (mode === WidgetMode.Embedded) {
        iframeRef.current?.setAttribute('frameborder', 'none')
      }

      setOpen(true)
      iframeRef.current?.setAttribute(
        'src',
        `${import.meta.env.VITE_WIDGET_URL}/v2/booking/`
      )
      if (startingMode === MessageType.StartMyBooking) {
        setStartingMode(MessageType.StartMyBooking)
      } else {
        setStartingMode(MessageType.Start)
      }
    }

    const detectTriggerButton = async (retries = 0) => {
      const openWidgetButtons = document.getElementsByClassName(triggerButtonId)
      const myBookingButtons =
        document.getElementsByClassName(widgetMyBookingId)
      if (retries * triggerButtonRetryDuration >= triggerButtonRetryTimeout)
        throw new Error(
          `Couldn't find trigger button with class ${triggerButtonId} after ${
            (retries * triggerButtonRetryDuration) / 1000
          } seconds.`
        )
      if (!openWidgetButtons.length) {
        await new Promise((resolve) =>
          setTimeout(resolve, triggerButtonRetryDuration)
        )
        detectTriggerButton(retries + 1)
        return
      }

      for (const button of openWidgetButtons) {
        button.addEventListener(
          'click',
          () => onStart(MessageType.Start),
          false
        )
      }

      for (const button of myBookingButtons) {
        button.addEventListener(
          'click',
          () => onStart(MessageType.StartMyBooking),
          false
        )
      }

      startTriggerButton.current = openWidgetButtons as unknown as HTMLElement[]
      startTriggerButtonMyBooking.current =
        myBookingButtons as unknown as HTMLElement[]
    }

    const onReady = () => {
      iframeRef.current?.focus()
      iframeRef.current?.contentWindow?.postMessage(
        {
          type: startingMode,
          data: {
            apiToken,
            mode,
            clinicIds,
            treatmentIds,
            theme,
            integrationType,
          },
        },
        iframeOrigin
      )
    }

    const onClose = async () => {
      const pBody = document.getElementsByTagName('body')[0]
      pBody.style.overflow = 'auto'
      pBody.style.position = 'relative'
      setOpen(false)
      await new Promise((resolve) => setTimeout(resolve, 200))
      iframeRef.current?.setAttribute('src', '')
    }

    const onMessage = (event: MessageEvent<Message>) => {
      if (
        !allowedOrigins.includes(event.origin) ||
        event.source !== iframeRef.current?.contentWindow
      )
        return

      switch (event.data.type) {
        case MessageType.Ready:
          onReady()
          break

        case MessageType.Close:
          onClose()
          break

        default:
          break
      }

      handleTypeMessageFromParentWindow(event)
    }

    if (mode === WidgetMode.Embedded && apiToken) {
      onReady()
      onStart()
    }

    window.addEventListener('message', onMessage, false)
    if (mode === WidgetMode.Modal) {
      detectTriggerButton()
    }

    return () => {
      window.removeEventListener('message', onMessage, false)
      for (const button of startTriggerButton.current || []) {
        button.removeEventListener('click', () => onStart(), false)
      }
      for (const button of startTriggerButtonMyBooking.current || []) {
        button.removeEventListener('click', () => onStart(), false)
      }
    }
  }, [
    hostURLParams,
    apiToken,
    mode,
    startingMode,
    clinicIds,
    treatmentIds,
    theme,
    integrationType,
  ])

  return (
    <>
      {mode && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 2147483647,
            opacity: open ? 1 : 0,
            pointerEvents: open ? 'auto' : 'none',
            transition: `opacity ${open ? '1s' : '.2s'} ease`,
          }}
        >
          <iframe
            id="booking-widget"
            style={{
              position: 'fixed',
              height: '100%',
              width: '100%',
              border: 'none',
            }}
            ref={iframeRef}
          />
        </div>
      )}
    </>
  )
}

let startedEmbeddingRetries = 0

const runWidget = async () => {
  if (window.tdlWidgetConfig && !window.tdlWidgetConfig?.mode) {
    window.tdlWidgetConfig = {
      ...window.tdlWidgetConfig,
      mode: WidgetMode.Modal,
    }
  }

  if (window.tdlWidgetConfig?.mode === WidgetMode.Embedded) {
    console.warn('Running embedded widget')

    const fontStyles = document.createElement('style')
    fontStyles.textContent = getFontStyles(import.meta.env.VITE_WIDGET_URL)
    document.head.appendChild(fontStyles)

    const iframe = document.createElement('iframe')
    iframe.src = `${window.location.href}`
    iframe.id = 'tdl-booking-widget-frame'
    document.head.appendChild(iframe)
    const widgetConfigScript = document.createElement('script')
    widgetConfigScript.textContent = `window.tdlWidgetConfig = ${JSON.stringify(
      window.tdlWidgetConfig
    )}`

    const script = document.createElement('script')
    script.type = 'module'
    script.src = `${import.meta.env.VITE_WIDGET_URL}/v2/REPLACE.js`

    const loaded = new Promise((resolve) => {
      script.onload = resolve
    })

    iframe.onload = () => {
      if (!iframe.contentDocument) {
        Promise.reject()
        return
      }

      iframe.contentDocument.head.appendChild(widgetConfigScript)
      iframe.contentDocument.head.appendChild(script)
    }

    loaded.then(() => {
      if (!iframe.contentWindow) {
        Promise.reject()
        return
      }

      iframe.contentWindow.initTDLWidget()
      const shadowRoot = document.getElementById(widgetHolderId)?.shadowRoot

      const styles = iframe.contentWindow.document.head.querySelector(
        '#css-injected-by-js'
      )

      if (shadowRoot && styles) {
        shadowRoot.appendChild(styles)
      }

      iframe.contentWindow.document.body.innerHTML = ''

      Promise.resolve()
    })

    Promise.resolve()
  } else if (window.tdlWidgetConfig?.mode === WidgetMode.Modal) {
    console.warn('Running modal widget')

    const holder = document.createElement('div')
    holder.setAttribute('id', widgetHolderId)
    document.body.prepend(holder)

    ReactDOM.createRoot(holder).render(<WidgetLoader />)
    Promise.resolve()
  } else {
    console.warn('No widget mode found')
    await new Promise((resolve) =>
      setTimeout(resolve, triggerButtonRetryDuration)
    )

    startedEmbeddingRetries++
    if (startedEmbeddingRetries <= 5) {
      runWidget()
    }
  }
}

window.runTDLBookingWidget = runWidget

runWidget()
